var dexieDb = new Dexie("InventoryManager");

class DB {
  constructor() {
    dexieDb.version(1).stores({
      products: "++id, data.barcode, data.symbol",
      columns: '++, columns',
      scanHistory: "++id, search, date, name",
      stats: "name, value"
      // configuration: "++,name,value"
    });
    dexieDb.open().catch((e) => {
      alert ("Open failed: " + e);
    });

    console.log('DexieDb is: ', dexieDb);
  }

  bulkAdd(items) {
    return dexieDb.products.bulkPut(items);
  }

  length() {
    return dexieDb.products.count();
  }

  setColumns(columnNames) {
    var _this = this;
    dexieDb.table('columns').clear().then(function() {
      return dexieDb.columns.put(columnNames).then(function (value) {
        _this.columnNames = columnNames;
      });
    });
  }

  getColumnNames() {
    return dexieDb.columns.where('id').equals(0);
  }

  get(key) {
    return dexieDb.products
        .where('data.symbol').equalsIgnoreCase(key)
        .or('data.barcode').equalsIgnoreCase(key)
        .toArray(function(items) {
      if (!items.length) { return undefined; }
      let out = items[0].data;
      out.id = items[0].id;
      return out;              
    });
  }

  update(item) {
    console.log("Updating the item", item);
    return dexieDb.products
                  .where('id').equals(item.id)
                  .modify({data: item});
  }

  clear(){
    return dexieDb.table('products').clear().then(function () {
      return dexieDb.table('stats').clear().then(function () {
        return dexieDb.table('scanHistory').clear().then(function () {
          return dexieDb.table('columns').clear();
        });
      });
    });
  }

  count() {
    return dexieDb.products.count();
  }

  getItems(offset, limit) {
    return dexieDb.products.offset(offset).limit(limit)
                  .toArray().then(function (items) {
      return items.map(function (item) {
        return item.data;
      });
    });
  }

  getDb() {
    return dexieDb;
  }

}

export var db = new DB();
