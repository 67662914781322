import {db} from './db.js';
import {history} from './history.js';
import {saveAs} from './FileSaver.js'

const CSV_DELIMETER = '|';
// UTILS
var toObj = (ks, vs) => ks.reduce((o,k,i)=> {o[k] = vs[i]; return o;}, {});


var dialog = document.getElementById('import-db-dialog');

dialog.querySelector('.close').addEventListener('click', function(e) {
  document.getElementById('import-db-dialog').close();
});

dialog.querySelector('.import').addEventListener('click', function(e) {
  document.getElementById('import-fileinput').click();
  db.clear();
  e.preventDefault();
});

// TODO: Add progress bar animation during the import process
var importProgressBar = document.getElementById('import-progressbar');


window.handleFiles = function(files) {
  var fr = new FileReader();
  fr.onload = function() {
    var rows = fr.result.split('\n');
    // var columnNames = rows[0].split('|');
    // TODO STH BROKEN HERE, NEWLINE IN VAT?????
    var columnNames = ["barcode", "ilosc", "symbol", "nazwa", "opis", "cena", "vat"]; 

    db.setColumns(columnNames);
    
    var items = [];
    for (var i = 1; i < rows.length ; i++) {
      var row = rows[i].split(CSV_DELIMETER);
      items.push({
        data: toObj(columnNames, row)
      });
    }
    db.bulkAdd(items);

    document.getElementById('import-db-dialog').close();
    
    vDB.refresh();
  }
  fr.readAsText(files[0]);
};

// VUE STUFF

// var data = { a: 1 }
var vDB = new Vue({
  el: '#database',
  data: {items: [], offset: 0, total: 0, page: {current: 0, total: 0}},
  methods: {
    showImportDialog: function () {
      document.getElementById('import-db-dialog').showModal();
    },
    exportDB: function() {
      var data = [];
      db.getDb().products.each(function (item) {
        data.push([item.data.barcode, item.data.scans, item.data.ilosc, item.data.symbol, item.data.nazwa, item.data.opis, item.data.cena, item.data.vat].join('|'));
      }).then(function () {
        var blob = new Blob([data.join('\n')], {type: "text/plain;charset=utf-8"});
        saveAs(blob, "products.csv");
      });
    },
    showClearDialog: function() {
      // TODO: show confirmation dialog
      if (confirm("Do you want to destroy the database?")) {
        db.clear();
        this.refresh(); 
      }
    },
    refresh: function() {
      db.getItems(this.offset, 5).then(function (items) {
        vDB.items = items;
      });
      db.count().then(function(num) {
        vDB.total = num;

        vDB.page.current = vDB.offset / 5;
        vDB.page.total = Math.floor(num / 5)
      });
    },
    prev: function() {
      if (this.offset === 0) { return; }
      this.offset -= 5;
      this.refresh();
    },
    next: function() {
      if (this.offset + 5 > this.toal) { return; }
      this.offset += 5;
      this.refresh();
    }
  }
});

vDB.refresh();


// TODO: Maybe makie it work for scanners 
// that do not put newliwne at the end
// should have a calibration probably
// 10 scans in a row to get the speed right
var scannerDetector = (function () {
  var buffer = '';

  var onFinished = function () {
    lastTickTime = undefined;
    vScan.submit();
  };

  var lastTickTime;
  var timeout;

  return {
    tick: function () {
      if (timeout) { clearTimeout(timeout); }

      var now = +new Date();
      if (!lastTickTime || now - lastTickTime > 50) {
        lastTickTime = now;
        return;
      }
      timeout = setTimeout(onFinished, 55);
    }
  }
})();

var vScan = new Vue({
  el: "#scan",
  data: {
    search: '',
    isScanning: false,
    isInputReadonly: true,
    item: {
      id: '', barcode: '', symbol: '',
      nazwa: '', ilosc: '', scans: '',
      cena: '', vat: '', opis: ''
    },
    historyItems: [],
    isEmpty: true
  },
  watch: {
    search: function(oldVal, newVal) {
      // scannerDetector.tick();
    }
  },
  methods: {
    toogleMode: function () {
      var _this = this;
      this.isScanning = !this.isScanning;
      if (this.isScanning) {
        setTimeout(function () {
          _this.inputFocus();
        }, 100);
      }
    },

    inputFocus: function () {
      document.querySelector('#search-input').focus();
    },

    editCancel: function () {
      this.isEmpty = true;
      vScan.clearEditForm();
      this.inputFocus();
      vScan.refresh();
    },

    editConfirm: function () {
      db.update(JSON.parse(JSON.stringify(vScan.item)));
      vScan.editCancel();
    },
    clearEditForm: function () {
      for (let value in vScan.item) {
        vScan.item[value] = '';
      }
      vScan.search = '';
    },
    refresh: function () {
      this.refreshTextfields();
      this.refreshHistory();
    },
    refreshTextfields: function () {
      var _this = this;
      setTimeout(function () {
        for (let textfield of document.querySelectorAll("#scan .mdl-js-textfield")) {
          if (!textfield.MaterialTextfield) { continue; }
          textfield.MaterialTextfield.checkDirty();
        }
      }, 100);
    },
    refreshHistory: function () {
      var _this = this;
      history.getAll().then(function(items) {
        _this.historyItems = items;
      });
    },
    toggleReadonly: function () {
      var _this = this;
      this.isInputReadonly = !this.isInputReadonly;
      setTimeout(function () {
        _this.inputFocus();
      }, 100);
    },
    showMoreHistory: function() {

    },
    keyup: function (e) {
      if (!this.isInputReadonly) {
        if (e.keyCode === 13) {
          this.submit();
        }
        return;
      }
      switch (e.keyCode) {
        case 13: // enter
          this.submit();
          break;
        case 8: // backspace
          this.search = this.search.slice(0, -1);
          break;
        default:
          this.search += e.key;
      }
      this.refreshTextfields();
    },
    submit: function() {
      this.isScanning = true;
      console.log("Gettng item", vScan.search);
      var _this = this;
      db.get(vScan.search).then(function(item) {
        if (!item) {
          _this.isEmpty = true;
          function handler () {
            alert("Not impmeneted yet!");
          }
          var data = {
            message: 'Item not found',
            timeout: 2000,
            actionHandler: handler,
            actionText: 'Add'
          };
          document.getElementById('snackbar').MaterialSnackbar.showSnackbar(data);
          return;
        }
        else { _this.isEmpty = false; }
        item.scans = parseInt(item.scans || 0) + 1;

        for (let value in item) {
          vScan.item[value] = item[value];
        }
        history.add(item).then(_this.refreshHistory);
      });
      vScan.refresh();
      // hide the keyboard
      document.querySelector('#search-input').blur();
    }
  }
});

(function() {
  vScan.refresh();
})();