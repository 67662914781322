import {db} from './db.js';

const HISTORY_ENTRIES_LIMIT = 2;

class History {
  getAll(){
    // get entries from scanHistory and return all items
    // matching the barcodes
    return new Dexie.Promise(function (resolve, reject) {
      return db.getDb().scanHistory.toArray().then(function (items) {
        var items = items.sort(function (a, b) { return b.date - a.date; });
        var out = [];
        var promises = [];
        for (let item of items) {
          promises.push(db.getDb().products
                                  .where('id').equals(item.id)
                                  .toArray().then(function (items) {
            out.push(items[0].data);
          }));
        }
        Dexie.Promise.all(promises).then(function () {
          return resolve(out);
        })
      });
    });
  }

  truncate(limit) {
    db.getDb().scanHistory.count().then(function (num) {
      if (num > HISTORY_ENTRIES_LIMIT) {
        db.getDb().scanHistory.toArray().then(function (items) {
          items = items.sort(function (a, b) { return a.date - b.date; });
          console.log("ITEMS sorted", items);
          var rmIds = items.map(function (item) {
            return item.id;
          });
          db.getDb().scanHistory.where('id').anyOf(rmIds.slice(0, num - limit)).delete().then(function (delCount) {
            console.log('Deleted ' + delCount + ' items from history');
          });
        });
      }
    });
  }

  increment() {
    db.getDb().stats.where('name').equals('scansNum')
              .toArray().then(function (items) {
      var scansNum = items[0] && items[0].value || 0;
      db.getDb().stats.put({name: 'scansNum', value: scansNum + 1});
    })
  }

  add(item){
    this.increment();
    this.truncate();

    return db.getDb().scanHistory.put({
      barcode: item.id,
      date: new Date(),
      name: "name-of-employee"
    });
  }
}

export var history = new History();
